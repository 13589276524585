<template>
  <step-base :cta-props="ctaProps" @click="nextClicked" with-login-link>
      <div class="ri-body margin-bottom-xxs">{{ signupText }}</div>
      <div class="ri-large-headline ri-alte margin-bottom-xxs">איך קוראים לך?</div>
      <div class="margin-top-ml">
        <text-input
          label-text="שם פרטי"
          :value="firstName"
          :onInput="checkAndSetFirstName"
          :error="false"/>
        <text-input
          label-text="שם משפחה"
          :value="lastName"
          :onInput="checkAndSetLastName"
          :error="false"/>
      </div>
  </step-base>
</template>

<script>

import Segment from '@/Segment';
import BrowserInfo from '@/mixins/BrowserInfo';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import StepBase from './StepBase';

export default {
  name: 'EnterName',
  mixins: [BrowserInfo],
  components: {
    TextInput: BaseUI.V2Components.TextInput,
    StepBase,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    signupText: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot(`${this.segmentEventPrefix}SignUp_V3_EnterName_Entered_Marketing`);
    Segment.trackUserGot(`${this.segmentEventPrefix}RegistrationEntered_Split`, {
      userAgentFromNavigator: this.userAgent,
      isFacebookApp: this.isFacebookApp,
    });
    Segment.trackUserGotToPixel(`${this.segmentEventPrefix}RE_Split`);
    Segment.trackUserGotToPixel(`${this.segmentEventPrefix}RegistrationEntered_Split_NameEntered`);
  },
  computed: {
    ...mapState('signup', ['firstName', 'lastName', 'segmentEventPrefix']),
    disabled() {
      return _.trim(this.firstName).length < 2 || _.trim(this.lastName).length < 2;
    },
    ctaProps() {
      return {
        buttonProps: {
          title: 'קדימה',
          disabled: this.disabled,
        },
      };
    },
  },
  methods: {
    ...mapMutations('signup', ['setFirstName', 'setLastName']),

    checkAndSetFirstName(value) {
      this.setFirstName(value);
    },
    checkAndSetLastName(value) {
      this.setLastName(value);
    },
    goToLogin() {
      Segment.trackUserInteraction('GoToLogin_Split');
      window.location.href = '/sr';
    },
    nextClicked() {
      Segment.trackUserInteraction(`${this.segmentEventPrefix}SignUp_V3_EnterName_NextClicked_Marketing`);
      Segment.trackUserGotToPixel(`${this.segmentEventPrefix}RegistrationEntered_Split_NameEntered_NextClicked`);
      this.nextStep();
    },
  },
};

</script>
<style lang="scss" scoped>
</style>
