<template>
  <step-base :cta-props="ctaProps" @click="nextClicked">
    <div class="ri-body  margin-bottom-xxs">{{ signupText }}</div>
    <div class="ri-large-headline ri-alte margin-bottom-xxs">היי {{ firstName }}!</div>
    <div class="ri-large-body">כדי לפתוח חשבון התנסות נצטרך את המייל שלך. מבטיחים לא להספים.</div>
    <div class="margin-top-l">
      <email-input
        label-text="אימייל"
        :value="email"
        :onInput="setEmail"
        :error="emailError"
        :onError="setEmailError"/>
    </div>
  </step-base>
</template>

<script>

import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapMutations, mapState } from 'vuex';
import StepBase from './StepBase';

export default {
  name: 'EnterEmail',
  components: {
    EmailInput: BaseUI.V2Components.EmailInput,
    StepBase,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    signupText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emailError: false,
    };
  },
  created() {
    Segment.trackUserGot(`${this.segmentEventPrefix}SignUp_V3_Email_Entered_Marketing`);
    Segment.trackUserGotToPixel(`${this.segmentEventPrefix}RegistrationEntered_Split_EmailEntered`);
  },
  computed: {
    ...mapState('signup', ['firstName', 'email', 'segmentEventPrefix']),
    disabled() {
      return this.email.length < 2 || this.emailError;
    },
    ctaProps() {
      return {
        buttonProps: {
          title: 'אפשר להמשיך',
          disabled: this.disabled,
        },
      };
    },
  },
  methods: {
    ...mapMutations('signup', ['setEmail']),
    setEmailError(value) {
      this.emailError = value;
    },
    nextClicked() {
      Segment.trackUserInteraction(`${this.segmentEventPrefix}SignUp_V3_Email_NextClicked_Marketing`);
      Segment.trackUserGotToPixel(`${this.segmentEventPrefix}RegistrationEntered_Split_EmailEntered_NextClicked`);
      if (this.emailError) {
        Segment.trackUserGotToPixel(`${this.segmentEventPrefix}RegistrationEntered_Split_EmailEntered_InvalidEmail`);
      } else {
        this.nextStep();
      }
    },
  },
};

</script>
<style lang="scss" scoped>

</style>
